import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from './layouts/main';
import NotFound from './views/Page404';
import { RedeemCode } from './views/RedeemCode/RedeemCode';
// ----------------------------------------------------------------------

export default function Router() {

  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, path: '/', element: <RedeemCode /> },
      ],
    },
    { path: '404', element: <NotFound /> },
    { path: '/', element: <Navigate to="/" replace /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}