import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import palette from '../theme/palette';

function Copyright() {
  return (
    <Typography style={{ textAlign: 'center' }} variant="body2" color={palette.mode === 'light'
      ? palette.text.primary
      : palette.gold.text}>
      {'Copyright © '}
      <Link color="inherit">
        Battlerise
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function StickyFooter() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <CssBaseline />
      <Box
        component="footer"
        sx={{
          py: 1,
          px: 2,
          mt: 'auto',
          width: '100%',
          bottom: 0,
          position: 'absolute',
          backgroundColor: (theme) =>
            palette.mode === 'light'
              ? palette.background.default
              : palette.background.navy,
        }}
      >
        <Container maxWidth="sm">
          {/* <Typography variant="body1">
            </Typography> */}
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}