import { useState } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import ResponsiveAppBar from './ResponsiveAppBar';
//

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  flexWrap: 'wrap',
  maxHeight: '100vh'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  // [theme.breakpoints.up('lg')]: {
  //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  // }
}));

// ----------------------------------------------------------------------


interface Props {
  handleChange?: () => any
}

export default function MainLayout({ handleChange }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <MainStyle>
        <ResponsiveAppBar />
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
