import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// ----------------------------------------------------------------------

interface Props {
  disabledLink?: Boolean,
  sx?: object,
};

export const Logo = ({ disabledLink = true, sx }: Props) =>  {
   const logo = <Box component="img" src="/static/logo.png" sx={{...sx, width:'100px'}} />

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
