import { Button, Typography } from "@mui/material";
import palette from "../../theme/palette";
import './Button.scss';

const typographyStyle: React.CSSProperties = {
  position: 'absolute',
  display: "block",
  color: palette.gold.text, fontWeight: 600
}

const buttonStyle: React.CSSProperties = {
  border: 'none',
  width: 'auto',
  margin: 'auto',
}

interface Props {
  text: String, height: Number, type: string, onchange?(): void
}

export default function ButtonDefault({ text, height, type, onchange }: Props) {

  const svgIcon = (
    <img alt="edit" style={{ height: `${height}px` }} src="/static/button_ui.png" />
  );
  //@ts-ignore
  return <Button  //@ts-ignore
    onClick={() => { if (onchange) onchange() }}
    type={type}
    fullWidth
    variant="outlined"
    sx={{ mt: 3, mb: 2 }}
    style={buttonStyle}
    startIcon={svgIcon}>
    <Typography variant='h6' style={typographyStyle}>{text}</Typography>
  </Button >
}