import InfoIcon from '@mui/icons-material/Help';
import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FC, FormEvent, Fragment, useEffect, useState } from 'react';
import ButtonDefault from '../../components/Button/Button';
import { CustomTooltip } from '../../components/CustomTooltip/CustomTooltip';
import Page from '../../components/Page';
import palette from '../../theme/palette';
import { stripHTML } from '../../utils';
import './RedeemCode.scss';
interface Props {
}
const styles = {
  containerMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    'minHeight': '100vh'
  },
  page: {
    backgroundImage: `url('/static/background.png')`,
    backgroundColor: palette.background.navy,
    backgroundSize: 'cover',
    overflowX: "scroll",
    overflowY: 'hidden',
    // display: 'inline-block',
    // overflow: 'hidden',
    position: 'fixed',
    width: '100%'
  },
  // backgroundImg: {
  //   pointerEvents: undefined,
  //   position: 'absolute',
  //   minHeight: '100%',
  //   maxWidth: '100vw',
  //   minWidth: '1300px',
  //   width: 'auto',
  //   overflow: 'hidden',
  //   zIndex: '0'
  // },
  div: { margin: 'auto', textAlign: "center" },
  box: {
    minHeight: '300px',
    backgroundColor: palette.background.navy,
    height: "auto",
    marginBottom: '100px',
    zIndex: 1,
    width: "inherit",
    padding: '3px',
    borderRadius: '5%',
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
};

export const RedeemCode: FC = () => {

  const [success, setSuccess] = useState(false);
  const [hasError, setHasError] = useState<string | Boolean>(false);
  const abortController = new AbortController()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hasError || success)
      setLoading(false);
  }, [hasError, success])

  const getData = async (playerId: FormDataEntryValue | null, promoCode: FormDataEntryValue | null) => {
    if (playerId && promoCode) {
      setLoading(true);
      try {
        const url = `${process.env.REACT_APP_API_URL}`
        const result = await fetch(url, {
          signal: abortController.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-functions-key': `${process.env.REACT_APP_API_KEY}`
          },
          body: JSON.stringify(
            { "playerId": stripHTML(playerId.toString()).trim(), "token": stripHTML(promoCode.toString()).trim() }
          ),
        })
        const res = await result.text();
        if (result.status === 200)
          setSuccess(true);
        else {
          setSuccess(false);
          setHasError(res);
        }
      } catch (err: any) {
        //@ts-ignore
        if (err.code !== 20) {
          // console.error(err);
          setSuccess(false);
          setHasError(err)
        }
      }
    }
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    getData(data.get('playerId'), data.get('promoCode'));
  };

  const resetState = () => {
    setSuccess(false);
    setHasError(false);
  }

  return (
    <Page title="Promo Codes" style={styles.page}>
      {/* <img
        //@ts-ignore
        style={styles.backgroundImg}
        src={`/static/background.png`}
        loading="lazy"
      /> */}

      <Container
        //@ts-ignore 
        style={styles.containerMain} component="main" maxWidth="xs">
        <Box className="frame-gradient" sx={styles.box}>
          {!success && !hasError &&
            <div style={{ padding: '5%' }}>

              {loading &&
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '47%'
                }}>
                  <LoadingButton fullWidth size="large" loading={loading} />
                </Box>
              } <>
                <Typography component="h1" variant="h5" textAlign={'center'}>
                  Redeem Battlerise Promo Code
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <div style={{ position: "relative" }}>
                    <TextField
                      type='number'
                      margin="normal"
                      // required
                      fullWidth
                      id="playerId"
                      label="Enter Player Id"
                      InputLabelProps={{
                        style: {
                          color: palette.gold.text
                        },
                      }}
                      name="playerId"
                      autoComplete="playerId"
                    // autoFocus
                    />
                    <CustomTooltip
                      title={
                        <Fragment>
                          <Typography color="inherit">How to find your Player Id?</Typography>
                          {"Open the game. Select "}<em>{"Settings"}</em>{" in the top-right corner. "}
                          {"Then, click on the "}<em>{"Get Player Id"}</em> {"button to find your Player ID."}
                          <img src="/static/playerid.jpg" width='200' />
                        </Fragment>
                      }
                    >
                      <IconButton style={{
                        position: 'absolute',
                        right: '2%',
                        top: '28%'
                      }}>
                        <InfoIcon />
                      </IconButton>
                    </CustomTooltip>
                  </div>
                  <TextField
                    margin="normal"
                    fullWidth
                    name="promoCode"
                    label="Enter Promo Code"
                    InputLabelProps={{
                      style: {
                        color: palette.gold.text
                      },
                    }}
                    // required
                    id="promoCode"
                    autoComplete="promoCode"
                  />
                  <div style={{ width: '100%', margin: '0.6rem 0px 0rem', 'justifyContent': 'center', display: 'flex' }}>
                    <ButtonDefault type='submit' height={40} text='REDEEM'></ButtonDefault>
                  </div>
                </Box>
              </>
            </div>
          }
          {success && !hasError &&
            //@ts-ignore
            <div style={styles.div}>
              <Typography component="h1" variant="h6">
                The promo code has been applied.
              </Typography>
              <Typography component="h1" variant="h6">
                Check out your rewards in the app.
              </Typography>
              <div style={{ width: '100%', margin: '0.6rem 0px 0rem', 'justifyContent': 'center', display: 'flex' }}>
                <ButtonDefault onchange={resetState} type="button" height={40} text="GO BACK"></ButtonDefault>
              </div>
            </div>}
          {!success && hasError &&
            //@ts-ignore
            <div style={styles.div}>
              <Typography component="h1" variant="h6">
                Oh no! It seems that something went wrong. {typeof (hasError) == 'string' && hasError}
              </Typography>
              <div style={{ width: '100%', margin: '0.6rem 0px 0rem', 'justifyContent': 'center', display: 'flex' }}>
                <ButtonDefault type="button" onchange={resetState} height={40} text='TRY AGAIN'></ButtonDefault>
              </div>
            </div>}
        </Box>
      </Container>
    </Page>

  );
}