import { ReactNode, forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
import StickyFooter from '../layouts/Footer';
// @mui

// ----------------------------------------------------------------------

interface Props {
  children: ReactNode,
  meta?: ReactNode,
  title?: String,
  style?: Object
}

const Page = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { title, children, style } = props;
  return (
    <div>
      <div style={{ height: 'auto', ...style }}>
        <Helmet>
          <title>{`${title} | Battlerise`}</title>
        </Helmet>

        <div ref={ref}>
          {children}
        </div>

      </div>
      <StickyFooter />
    </div>
  )
});

export default Page;
